import {t} from "../fake-translation";

const ShapeCategoryItem = ({ category, onClick, isActive }) => {
  const clickHandler = () => {
    onClick(category);
  };

  return (
    <div
        id={`${category}-item`}
      key={category}
      className={`shape_selector--sidebar-item ${isActive ? "active" : ""}`}
      onClick={clickHandler}
    >
      <span>{t(category)}</span>
    </div>
  );
};

export default ShapeCategoryItem;
