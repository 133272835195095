


const messages = {
    "basic": "Հիմնական",
    "objects": "Օբյեկտներ",
    "animals": "Կենդանիներ",
    "nature": "Բնություն",
    "food": "Սնունդ",
    "symbols": "Նշաններ",
    "education": "Կրթություն",
    "arts": "Արվեստ",
    "science": "Գիտություն",
    "people": "Մարդիկ",
    "places": "Վայրեր",
    "activities": "Գործունեություն",
    "transportation": "Տրանսպորտ",
    "work": "Աշխատանք",

    "star": "Աստղ",
    "heart": "Սիրտ",
    "triangle": "Եռանկյուն",
    "eclipse": "Խավարում",
    "glasses": "Ակնոց",
    "bird": "Թռչուն",
    "chevron": "Էսպի",
    "shell": "Խեցին",
    "bug": "Սխալ",
    "fish": "Ձուկ",
    "rabbit": "Նապաստակ",
    "wolf": "Գայլ",
    "hand": "Ձեռք",
    "wavelength": "Ալիքի երկարություն",
    "leaf": "Տերեւ",
    "apple": "Խնձոր",
    "eye": "Աչք",
    "dolphin": "Դելֆին",
    "bell": "Զանգ",
    "ice_cream": "Պաղպաղակ",
    "sun": "Արև",
    "ant": "Մրջյուն",
    "cloud": "Ամպ",
    "mister": "Միստր",
    "cheer": "Ոգևորություն",
    "brother": "Եղբայր",
    "better than": "Ավելի լավ, քան",
    "elementary_school": "Տարրական դպրոց",
    "flower": "Ծաղիկ",
    "giraffe": "Ժիրաֆ",
    "smoke": "Ծուխ",
    "hat": "Գլխարկ",
    "cookie": "Թխվածքաբլիթ",
    "microscope": "Միկրոսկոպ",
    "test_tube": "Փորձանոթ",
    "cat": "Կատու",
    "happy": "Երջանիկ",
    "robot": "Ռոբոտ",
    "home": "Տուն",
    "planet": "Մոլորակ",
    "ball": "Գնդակ",
    "globe": "Գլոբուս",
    "musical note": "Նոտա",
    "sports": "Սպորտ",
    "football": "Ֆուտբոլ",
    "basketball": "Բասկետբոլ",
    "skateboarding": "Սքեյթբորդ",
    "airplane": "Ինքնաթիռ",
    "train": "Գնացք",
    "bus": "Ավտոբուս",
    "truck": "Բեռնատար",
    "office": "Գրասենյակ",
    "police": "Ոստիկանություն",
    "fisherman": "Ձկնորս",
    "suitcase": "Ուղեբեռ"
}


export const t = (key) => {
    return messages[key]
}
